import React from "react";
import "../../styles/Footer.css"

const Footer = () => {

    return (
        <div className="text-white  text-center">
            <div className="container px-5 py-8 mx-auto items-center">
                <span className="text-gray-600 ml-1" rel="noopener noreferrer">© 2022 —@winnerDedji</span>
            </div>
        </div>
    );
}

export default Footer;